<template>
  <div class="securitycameras">
    <section>
      <div class="container">

 <div class="row">
<div class="col-12"></div>

<div class="col-5">
  <div style="padding-right:40px;"><img src="img/watchdog.png" class="img-fluid"/></div>
</div>

<div class="col-7">
  <h4>Our Mission</h4>
<p>To help business owners protect, secure and stay in
  control of their business from anywhere and at anytime in the world.</p>

          <h5>About Watchdog Security</h5>
          <p>Watchdog Security is based out of Houston, Texas
            with offices in Spring, Texas and the Florida Keys. Our top priority
            is to deliver value with quality products,
            professional service and a commitment to excellence.
          </p>

          <p>We have been in business for 20 years and have
            developed long term, high value relationships
            with our customers.
          </p>

         </div>
        </div>
       </div>
    </section>
   </div>
</template>

<script>

export default {
  name: 'About',
  components: {
  },

};
</script>
<style scoped>

</style>
